.Toastify__toast-container {
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 9999px);
  position: fixed;
  width: 320px;
  box-sizing: border-box;
  color: #fff; }[dir] .Toastify__toast-container {
  padding: 4px; }
  .Toastify__toast-container--top-left {
    top: 1em; }
  [dir=ltr] .Toastify__toast-container--top-left {
  left: 1em; }
  [dir=rtl] .Toastify__toast-container--top-left {
    right: 1em; }
  .Toastify__toast-container--top-center {
    top: 1em; }
  [dir=ltr] .Toastify__toast-container--top-center {
  left: 50%;
  margin-left: -160px; }
  [dir=rtl] .Toastify__toast-container--top-center {
    right: 50%;
    margin-right: -160px; }
  .Toastify__toast-container--top-right {
    top: 1em; }
  [dir=ltr] .Toastify__toast-container--top-right {
  right: 1em; }
  [dir=rtl] .Toastify__toast-container--top-right {
    left: 1em; }
  .Toastify__toast-container--bottom-left {
    bottom: 1em; }
  [dir=ltr] .Toastify__toast-container--bottom-left {
  left: 1em; }
  [dir=rtl] .Toastify__toast-container--bottom-left {
    right: 1em; }
  .Toastify__toast-container--bottom-center {
    bottom: 1em; }
  [dir=ltr] .Toastify__toast-container--bottom-center {
  left: 50%;
  margin-left: -160px; }
  [dir=rtl] .Toastify__toast-container--bottom-center {
    right: 50%;
    margin-right: -160px; }
  .Toastify__toast-container--bottom-right {
    bottom: 1em; }
  [dir=ltr] .Toastify__toast-container--bottom-right {
  right: 1em; }
  [dir=rtl] .Toastify__toast-container--bottom-right {
    left: 1em; }

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw; }
  [dir] .Toastify__toast-container {
    padding: 0;
    margin: 0; }
  [dir=ltr] .Toastify__toast-container {
    left: 0; }
  [dir=rtl] .Toastify__toast-container {
    right: 0; }
    .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
      top: 0; }
    .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
      bottom: 0; }
    [dir=ltr] .Toastify__toast-container--rtl {
    right: 0;
    left: initial; }
    [dir=rtl] .Toastify__toast-container--rtl {
      left: 0;
      right: initial; } }

.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif; }

[dir] .Toastify__toast {
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer; }

[dir=ltr] .Toastify__toast {
  direction: ltr; }

[dir=rtl] .Toastify__toast {
  direction: rtl; }
  [dir=ltr] .Toastify__toast--rtl {
  direction: rtl; }
  [dir=rtl] .Toastify__toast--rtl {
    direction: ltr; }
  .Toastify__toast--default {
    color: #aaa; }
  [dir] .Toastify__toast--default {
    background: #fff; }
  [dir] .Toastify__toast--info {
    background: #3498db; }
  [dir] .Toastify__toast--success {
    background: #07bc0c; }
  [dir] .Toastify__toast--warning {
    background: #f1c40f; }
  [dir] .Toastify__toast--error {
    background: #e74c3c; }
  .Toastify__toast-body {
    -ms-flex: 1;
        flex: 1; }
  [dir] .Toastify__toast-body {
    margin: auto 0; }

@media only screen and (max-width: 480px) {
  [dir] .Toastify__toast {
    margin-bottom: 0; } }

.Toastify__close-button {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  outline: none;
  opacity: 0.7;
  transition: 0.3s ease;
  -ms-flex-item-align: start;
      align-self: flex-start; }

[dir] .Toastify__close-button {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer; }
  .Toastify__close-button--default {
    color: #000;
    opacity: 0.3; }
  .Toastify__close-button:hover, .Toastify__close-button:focus {
    opacity: 1; }

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1); }
  100% {
    transform: scaleX(0); } }

.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7; }

[dir] .Toastify__progress-bar {
  background-color: rgba(255, 255, 255, 0.7); }

[dir=ltr] .Toastify__progress-bar {
  left: 0;
  transform-origin: left; }

[dir=rtl] .Toastify__progress-bar {
  right: 0;
  transform-origin: right; }
  [dir=ltr] .Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards; }
  [dir=rtl] .Toastify__progress-bar--animated {
    animation: Toastify__trackProgress linear 1 forwards; }
  .Toastify__progress-bar--controlled {
    transition: transform .2s; }
  [dir=ltr] .Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right; }
  [dir=rtl] .Toastify__progress-bar--rtl {
    left: 0;
    right: initial;
    transform-origin: left; }
  [dir=ltr] .Toastify__progress-bar--default {
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); }
  [dir=rtl] .Toastify__progress-bar--default {
    background: linear-gradient(to left, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); }

@keyframes Toastify__bounceInRight-ltr {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceInRight-rtl {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutRight-ltr {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

@keyframes Toastify__bounceOutRight-rtl {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); } }

@keyframes Toastify__bounceInLeft-ltr {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceInLeft-rtl {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutLeft-ltr {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); } }

@keyframes Toastify__bounceOutLeft-rtl {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

@keyframes Toastify__bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

@keyframes Toastify__bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0); }
  75% {
    transform: translate3d(0, -10px, 0); }
  90% {
    transform: translate3d(0, 5px, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); } }

[dir=ltr] .Toastify__bounce-enter--top-left, [dir=ltr] .Toastify__bounce-enter--bottom-left {
  animation-name:  Toastify__bounceInLeft-ltr ; }

[dir=rtl] .Toastify__bounce-enter--top-left, [dir=rtl] .Toastify__bounce-enter--bottom-left {
  animation-name:  Toastify__bounceInLeft-rtl ; }

[dir=ltr] .Toastify__bounce-enter--top-right, [dir=ltr] .Toastify__bounce-enter--bottom-right {
  animation-name:  Toastify__bounceInRight-ltr ; }

[dir=rtl] .Toastify__bounce-enter--top-right, [dir=rtl] .Toastify__bounce-enter--bottom-right {
  animation-name:  Toastify__bounceInRight-rtl ; }

[dir=ltr] .Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown; }

[dir=rtl] .Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown; }

[dir=ltr] .Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp; }

[dir=rtl] .Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp; }

[dir=ltr] .Toastify__bounce-exit--top-left, [dir=ltr] .Toastify__bounce-exit--bottom-left {
  animation-name:  Toastify__bounceOutLeft-ltr ; }

[dir=rtl] .Toastify__bounce-exit--top-left, [dir=rtl] .Toastify__bounce-exit--bottom-left {
  animation-name:  Toastify__bounceOutLeft-rtl ; }

[dir=ltr] .Toastify__bounce-exit--top-right, [dir=ltr] .Toastify__bounce-exit--bottom-right {
  animation-name:  Toastify__bounceOutRight-ltr ; }

[dir=rtl] .Toastify__bounce-exit--top-right, [dir=rtl] .Toastify__bounce-exit--bottom-right {
  animation-name:  Toastify__bounceOutRight-rtl ; }

[dir=ltr] .Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp; }

[dir=rtl] .Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp; }

[dir=ltr] .Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown; }

[dir=rtl] .Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown; }

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes Toastify__zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

[dir=ltr] .Toastify__zoom-enter {
  animation-name: Toastify__zoomIn; }

[dir=rtl] .Toastify__zoom-enter {
  animation-name: Toastify__zoomIn; }

[dir=ltr] .Toastify__zoom-exit {
  animation-name: Toastify__zoomOut; }

[dir=rtl] .Toastify__zoom-exit {
  animation-name: Toastify__zoomOut; }

@keyframes Toastify__flipIn-ltr {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    transform: perspective(400px); } }

@keyframes Toastify__flipIn-rtl {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, 20deg);
    animation-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, -10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, 5deg); }
  to {
    transform: perspective(400px); } }

@keyframes Toastify__flipOut-ltr {
  from {
    transform: perspective(400px); }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

@keyframes Toastify__flipOut-rtl {
  from {
    transform: perspective(400px); }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, 20deg);
    opacity: 1; }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    opacity: 0; } }

[dir=ltr] .Toastify__flip-enter {
  animation-name:  Toastify__flipIn-ltr ; }

[dir=rtl] .Toastify__flip-enter {
  animation-name:  Toastify__flipIn-rtl ; }

[dir=ltr] .Toastify__flip-exit {
  animation-name:  Toastify__flipOut-ltr ; }

[dir=rtl] .Toastify__flip-exit {
  animation-name:  Toastify__flipOut-rtl ; }

@keyframes Toastify__slideInRight-ltr {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInRight-rtl {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInLeft-ltr {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInLeft-rtl {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideOutRight-ltr {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0); } }

@keyframes Toastify__slideOutRight-rtl {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0); } }

@keyframes Toastify__slideOutLeft-ltr {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0); } }

@keyframes Toastify__slideOutLeft-rtl {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0); } }

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0); } }

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0); } }

[dir=ltr] .Toastify__slide-enter--top-left, [dir=ltr] .Toastify__slide-enter--bottom-left {
  animation-name:  Toastify__slideInLeft-ltr ; }

[dir=rtl] .Toastify__slide-enter--top-left, [dir=rtl] .Toastify__slide-enter--bottom-left {
  animation-name:  Toastify__slideInLeft-rtl ; }

[dir=ltr] .Toastify__slide-enter--top-right, [dir=ltr] .Toastify__slide-enter--bottom-right {
  animation-name:  Toastify__slideInRight-ltr ; }

[dir=rtl] .Toastify__slide-enter--top-right, [dir=rtl] .Toastify__slide-enter--bottom-right {
  animation-name:  Toastify__slideInRight-rtl ; }

[dir=ltr] .Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown; }

[dir=rtl] .Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown; }

[dir=ltr] .Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp; }

[dir=rtl] .Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp; }

[dir=ltr] .Toastify__slide-exit--top-left, [dir=ltr] .Toastify__slide-exit--bottom-left {
  animation-name:  Toastify__slideOutLeft-ltr ; }

[dir=rtl] .Toastify__slide-exit--top-left, [dir=rtl] .Toastify__slide-exit--bottom-left {
  animation-name:  Toastify__slideOutLeft-rtl ; }

[dir=ltr] .Toastify__slide-exit--top-right, [dir=ltr] .Toastify__slide-exit--bottom-right {
  animation-name:  Toastify__slideOutRight-ltr ; }

[dir=rtl] .Toastify__slide-exit--top-right, [dir=rtl] .Toastify__slide-exit--bottom-right {
  animation-name:  Toastify__slideOutRight-rtl ; }

[dir=ltr] .Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp; }

[dir=rtl] .Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp; }

[dir=ltr] .Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown; }

[dir=rtl] .Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown; }